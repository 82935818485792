
<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="show" scrollable persistent max-width="500px">
        <!-- <template v-slot:activator="{ on }">
        <v-btn color="primary" dark v-on="on">Open Dialog</v-btn>
        </template>-->
        <v-card>
          <v-card-title><v-icon>mdi-clipboard-list</v-icon>{{ $t('sharelist.headermessage') }}</v-card-title>

          <v-card-text>
            <v-layout row wrap align-center justify-center>
              <v-flex xs11 lg11 ma-5>
                <v-card>
                  <v-container fluid>
                    <v-row align="center">
                      <v-col cols="12">
                        <v-layout row wrap justify-center>
                          <v-flex lg11 xs11>
                            <!-- <v-divider></v-divider> -->
                            <v-list flat>
                              <v-subheader>{{ $t('sharelist.sharewith') }}</v-subheader>
                              <v-divider></v-divider>
                              <v-layout row wrap justify-center>
                                <!-- <v-subheader>{{ $t('sharefile.sharewith') }}</v-subheader> -->

                              <!-- ข้อมูลการแชร์ -->
                                <!-- <v-list-item-group>
                                  <v-list-item v-for="(email) in emailadd" :key="email.id">
                                    <v-flex lg9 xs7>
                                      <v-list-item-content>
                                        <v-list-item-title v-text="email.email"></v-list-item-title>
                                      </v-list-item-content>
                                    </v-flex>
                                    <v-flex lg2 xs2>
                                      <v-list-item-action>
                                        <v-chip v-text="$t(email.status.text)" color="yellow"></v-chip>
                                      </v-list-item-action>
                                    </v-flex>
                                  </v-list-item>
                                </v-list-item-group> -->
                              </v-layout>
                            </v-list>
                          </v-flex>
                        </v-layout>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>

            <v-spacer></v-spacer>
             <v-btn
              color="blue darken-1"
              text
              @click="$emit('closelistshare' ),closevalues(),$emit('openDrag')"
            >{{$t('sharelist.cancelbutton')}}</v-btn>
            <v-btn
              color="blue darken-1"
              dark
               @click="$emit('openDrag')"
           >{{$t('sharelist.cancelsharefile')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

 

<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
const editsharefile = () => import("../optional/dialog-editsharefile");
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});

export default {
  props: ["show", "filedata"],
  components: { editsharefile },
  data: function() {
    return {    
      showdialog: false,
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active","color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId"
    ])
  },
  methods: {
    opendialog() {
      this.editsharefile = true;
      this.$emit('closeDrag')
    }
  }
};
</script>
